(function () {
    function MegaMenu(el) {
        if(el) {
            this.el       = el;
            this.btnsLevel1 = this.el.querySelectorAll('.nav-primary__link');
            this.submenus = this.el.querySelectorAll('.submenu-primary');
            this.btnBack = this.el.querySelectorAll('.submenu-primary__back');
            this.submenuOpen = true;
            this.init();

            // Désactive l'ouverture par défaut du menu de 2eme niveau sur mobile
            if (window.screen.width <= 768) {
                this.el.querySelectorAll('.nav-primary__link[aria-expanded="true"]').forEach( btn => {
                    btn.setAttribute('aria-expanded', false);
                });
                this.el.querySelectorAll('.submenu-primary:not([hidden])').forEach( panel => {
                    panel.setAttribute('hidden', '');
                });
            }
        }
    }

    MegaMenu.prototype.init = function() {
        // Clic sur les boutons de niveau 1
        this.btnsLevel1.forEach( btn => {
            btn.addEventListener('click', function (e) {
                this.clickLevel1(e)
            }.bind(this), false);
        });

        // Fermeture du sous-menu sur mobile
        this.btnBack.forEach( btn => {
            btn.addEventListener('click', function (e) {
                this.closeLevel2(e)
            }.bind(this), false);
        });
    };


    // Clique sur un bouton de niveau 1
    MegaMenu.prototype.clickLevel1 = function(e) {
        const currentBtn = e.currentTarget;
        this.submenuOpen = true;

        // *** boutons de niveau 1 ***
        // Désactive tous les boutons
        this.btnsLevel1.forEach( btn => {
            btn.setAttribute('aria-expanded', false);
        });
        // Active le bouton courant
        currentBtn.setAttribute('aria-expanded', true);


        // *** sous-menus ***
        // Masque tous les sous-menus
        this.submenus.forEach( panel => {
            panel.setAttribute('hidden', '');
        });
        // Affiche le sous-menu courant
        const currentSubmenu = this.el.querySelector('#' + currentBtn.getAttribute('aria-controls'));
        if(currentSubmenu) {
            currentSubmenu.removeAttribute('hidden');
        }
    };

    // Fermeture du sous-menu sur mobile
    MegaMenu.prototype.closeLevel2 = function(e) {
        this.submenuOpen = false;

        // *** boutons de niveau 1 ***
        // Désactive tous les boutons
        this.btnsLevel1.forEach( btn => {
            btn.setAttribute('aria-expanded', false);
        });


        // *** sous-menus ***
        // Masque tous les sous-menus
        this.submenus.forEach( panel => {
            panel.setAttribute('hidden', '');
        });
    };

    // Initialisation du mega menu
    const megamenu = new MegaMenu(document.getElementById('overlay-menu'));
})();