(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(slider => {
        const counterCurrentItem = slider.querySelector('.js-splide-current');
        const counterTotalItem = slider.querySelector('.js-splide-total');

        const splide = new Splide(slider, {
            pagination: false,
            autoHeight: true,
            // Slider en mode loop et autoplay si besoin
            autoplay: true,
            pauseOnHover: false,
            pauseOnFocus: false,
            interval: 5000,
            type: 'loop',
        });

        splide.on( 'mounted move', function () {
            counterCurrentItem.textContent = (splide.index + 1).toString().padStart(2, '0');
            counterTotalItem.textContent = (splide.Components.Controller.getEnd() + 1).toString().padStart(2, '0');
        } );

        splide.mount();
    });

})();