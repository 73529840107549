// Boutons "Voir plus", quand texte tronqué sur les blocs "À propos"
// ==========================================================================
const blocViewMore = document.querySelectorAll('.cover-edito .js-view-more');
blocViewMore.forEach(item => {
    const content = item.querySelector('[data-view-more-content]');
    const trigger = item.querySelector('[data-view-more-trigger]');

    // Comportement de viewmore sur le bloc si sa hauteur est suffisante
    if (content && content.getBoundingClientRect().height >= 88) {
        item.classList.add('view-more-active');

        trigger.addEventListener('click', function () {
            item.classList.toggle('is-expanded');
        });
    }
});