class thListingSlider {

    options = {
        selectorSliders: '.js-slider-overlay-map',
    };

    sliders;

    constructor(options, listingManager) {

        options = $.extend(true, this.options, options);
        this.options = options;
        this.sliders = [];

        this.init();
    }

    init()
    {
        const _sliders = document.querySelectorAll(this.options.selectorSliders);

        _sliders.forEach( (slider) => {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false
                    },
                }
            }).mount();

            this.sliders.push(splide);
        });

    }

    update()
    {
        this.sliders.forEach(function (splide) {
            splide.destroy().mount();
        })
    }
}

// Init des sliders
